.form-nft {
  border-bottom: 1px solid#ced4da !important;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  border-radius: 0 !important;
}
.left-content {
  padding: 4em;
}
.nft-btn {
  background-color: #f42f54 !important;
  border: none !important;
  padding: 1em 3em !important;
}
.nft-page-form h2 {
  margin: 1em 0 0;
  font-weight: 500;
  position: relative;
  text-align: center;
}

.nft-page-form {
  border: 1px solid #ced4da;
  margin: 2em 0 0;
}
.left-content {
  border-left: 1px solid #ced4da;
}
.mb-6 {
  margin: 0 0 3em !important;
}
