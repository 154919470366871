@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap);
.home-page {
  font-family: "Poppins", sans-serif;
}
.sub-title {
  display: flex;
  list-style: none;
  padding: 0;
  align-items: center;
  justify-content: center;
}
.sub-title li {
  padding: 0 1em;
  align-items: center;
  justify-content: center;
}
.sub-title li a {
  font-weight: 600;
}
.image-card-link a {
  font-weight: 600;
}
.sec-title {
  color: rgb(0, 0, 0);
  font-size: 5em;
  font-weight: 700;
}
.navbar-light .navbar-brand {
  color: rgb(0, 0, 0) !important;
}
.navbar-light .navbar-nav .nav-link {
  color: rgb(0, 0, 0) !important;
}
.sec-para {
  text-align: center;
  color: rgb(0, 0, 0);
}
.home-header {
  max-width: 800px;
  margin: 0 auto;
  padding: 15px 20px;
  position: relative;
}
/* .home-page {
  background-image: url("./images/bg.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  position: relative;
} */
/* .home-page::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(24, 24, 24, 0.5);
} */

.image-title {
  color: rgb(0, 0, 0);
  font-weight: 600;
  font-size: 1em;
}
.sec-images {
  width: 400px;
  height: 300px;
}

.nft-images {
  position: relative;
}
.image-card {
  margin: 1em 0;
}

.home-page {
  font-family: "Poppins", sans-serif;
}
.sub-title {
  display: flex;
  list-style: none;
  padding: 0;
  align-items: center;
  justify-content: center;
}
.sub-title li {
  padding: 0 1em;
  align-items: center;
  justify-content: center;
}
.sub-title li a {
  font-weight: 600;
}
.image-card-link a {
  font-weight: 600;
}
.sec-title {
  color: rgb(0, 0, 0);
  font-size: 5em;
  font-weight: 700;
}
.navbar-light .navbar-brand {
  color: rgb(0, 0, 0) !important;
}
.navbar-light .navbar-nav .nav-link {
  color: rgb(0, 0, 0) !important;
}
.sec-para {
  text-align: center;
  color: rgb(0, 0, 0);
}
.home-header {
  max-width: 800px;
  margin: 0 auto;
  padding: 15px 20px;
  position: relative;
}
.image-title {
  color: rgb(0, 0, 0);
  font-weight: 600;
  font-size: 1em;
  margin: 3em 0 1em;
}
.sec-images {
  width: 400px;
  height: 300px;
}

.nft-images {
  position: relative;
}
.image-card {
  margin: 1em 0;
}

.form-nft {
  border-bottom: 1px solid#ced4da !important;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  border-radius: 0 !important;
}
.left-content {
  padding: 4em;
}
.nft-btn {
  background-color: #f42f54 !important;
  border: none !important;
  padding: 1em 3em !important;
}
.nft-page-form h2 {
  margin: 1em 0 0;
  font-weight: 500;
  position: relative;
  text-align: center;
}

.nft-page-form {
  border: 1px solid #ced4da;
  margin: 2em 0 0;
}
.left-content {
  border-left: 1px solid #ced4da;
}
.mb-6 {
  margin: 0 0 3em !important;
}

